import React from 'react'
import { verifySectionThreeContent } from '../../data/verifyContent';
import "./verify.css";

export const VerifySectionThree = () => {
  return (
      <section className='py-8 lg:py-16'>
          <h1 className='font-[700] w-11/12 lg:w-3/4 lg:text-center mx-auto text-[35px]'>{verifySectionThreeContent.title.normal}<span className='text-[#985CF1]'>{verifySectionThreeContent.title.coloured}</span>{verifySectionThreeContent.title.normalContinuation}</h1>
          <div className='flex flex-col md:flex-row w-11/12 mx-auto mt-10'>
              {
                  verifySectionThreeContent.listData.map((data, index) => {
                      return (
                          <div className={`${data.class} bg-[#985CF1] px-4 py-14 text-white flex flex-col items-center`} key={index}>
                              <img src={data.icon} alt="verifyicons" />
                              <p className='mt-10 text-center'>{data.text}</p>
                          </div>
                      )
                  })
              }
          </div>
    </section>
  )
}
