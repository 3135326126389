import React from 'react'
import { verifySectionTwoContent } from '../../data/verifyContent'

export const VerifySectionTwo = () => {
  return (
      <section  className='h-auto relative'>
          <div className='absolute gradient z-[-1] h-[100%] w-[100%]'></div>
          <div className='xl:w-3/5 mx-auto space-y-5 py-[40px] px-4 md:px-14'>
              <h4 className='font-[700] text-[35px] lg:text-center'>{verifySectionTwoContent.title.normal}<span className='text-[#985CF1]'>{verifySectionTwoContent.title.coloured}</span>{verifySectionTwoContent.title.normalContinuation}</h4>
              <p className='lg:text-center font-[400] leading-[30px] lg:leading-[40px] md:text-[18px]'>{verifySectionTwoContent.paraLine}</p>
          </div>
    </section>
  )
}
