import React from "react";
import { Layout } from "../components/Layout";
import { VerifyPage } from "../components/Verify/VerifyPage";
import "../components/index.css";

const Verify = () => {
    return (
        <Layout>
            <VerifyPage />
        </Layout>
    )
}

export default Verify;