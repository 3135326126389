import React from 'react'
import { verifySectionFourContent } from '../../data/verifyContent'
import { FeatureBox } from '../FeatureBox'

export const VerifySectionFour = () => {
  return (
      <section className='w-11/12 mx-auto'>
         <h1 className='font-[700] text-[30px] lg:text-[45px] text-center py-16'>{verifySectionFourContent.title.normal}<span className='text-[#985CF1]'>{verifySectionFourContent.title.coloured}</span>{verifySectionFourContent.title.normalContinuation}</h1>
          <div>
              {verifySectionFourContent.data.map((data, index) => {
                  return <FeatureBox {...data} key={index} />
              })}
        </div>
    </section>
  )
}
