import verifyBanner from "../images/verifyBanner.svg";
import verifyRecycle from "../images/verifyRecycle.svg";
import verifyPlug from "../images/verifyPlug.svg";
import verifyProfile from "../images/verifyProfile.svg";
import verifyTimeline from "../images/verifyTimeline.svg";
import payrollBanner from "../images/payrollBanner.svg";


export const verifyBannerContent = {
    title: {
        blackColor: "Revamp secure on-boarding of employees ",
        whiteColor:"with foolproof background verification"
    },
    paraLine: "Make an agile team with authentic and AI powered employees background verification. Shield your brand image with Spectrawise's product that enables team's workflow and  synchronisation of workforce management.",
    button: "Book a Demo",
    image:verifyBanner
}

export const verifySectionTwoContent = {
    title: {
        normal: "Stay abreast of ",
        coloured: "employee backgrounds ",
        normalContinuation:"in a unique way!"
    },
    paraLine:"We have served around 250+ companies in bulk hiring. Our AI powered software Prism has enabled organisations of different sizes to verify employees' background errorlessly."
}

export const verifySectionThreeContent = {
    title: {
        normal: "India's ",
        coloured: "best background verification company ",
        normalContinuation:"has got your back as a single platform solution for workforce management."
    },
    listData: [
         {
        icon: verifyRecycle,
        text: "Quick access to employee background details",
        class:"firstBlock"
    },
    {
        icon: verifyPlug,
        text:"A smooth integration through APIs",
        class:"secondBlock"
    },
    {
        icon: verifyProfile,
        text:"Customised verification processes to meet diverse needs",
        class:"thirdBlock"
    },
    {
        icon: verifyTimeline,
        text:"Advanced reporting and automatic alerts & notifications",
        class:"fourthBlock"
    }
    ]
}

export const verifySectionFourContent = {
    title: {
        normal: "A ",
        coloured: "single integrated environment ",
        normalContinuation:"that ensures wholesome verification of employees' background"
    },
    data: [
        {
            title: "BGV Configuration",
            dimLine: "Finest Background verification skimming even the minute details.",
            listData: [
                "Protects your company against frauds",
                "Criminal database research",
                "Checks employees' background on diverse aspects like drug use, education, employment and more"
            ],
            image:payrollBanner
        },
        {
            title: "Client Onboarding",
            dimLine: "Client onboarding is much more than a series of follow-up meetings. Our software facilitates",
            listData: [
                "Organising and structuring client answers.",
                "Continually enhance your plan with crowdsourced suggestions",
                "Automate data collection"
            ],
            image:payrollBanner
        },
        {
            title: "Employee Onboarding",
            dimLine: "Ensure that your workforce is hiding nothing",
            listData: [
                "Faster bulk or single hiring",
                "Safe hiring with BGV software",
                "Pre-employment tests customised to your firm can help you hire the top individuals."
            ],
            image:payrollBanner
        },
        {
            title: "Workload Management",
            dimLine: "Bid goodbye to piles of books",
            listData: [
                "Precise results",
                "Manage and track your work",
                "Utilize scalable computing and analytics resources all at once."
            ],
            image:payrollBanner
        },
        {
            title: "Emails and Reports",
            dimLine: "Upskill your email marketing",
            listData: [
                "Learn more about the recipients of email.",
                "Generate client-specific campaign reports",
                "Utilize engagement analytics to identify best-performing emails."
            ],
            image:payrollBanner
        }
    ]
}